import styled from "styled-components";
import {Welcome} from "./welcome";
import {Header} from "./header";
import {HowItWorks} from "./how-it-works";
import {Reviews} from "./reviews";
import {Faq} from "./faq";
import {Bookmakers} from "./bookmakers";
import {Calculator} from "./calculator";
import {Pricing} from "./pricing";
import {Footer} from "./footer";
import {useRef} from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(30, 30, 30);
    min-height: 100vh;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    gap: 64px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 256px;
    padding: 0 10px 64px 10px;
`

export function NewLandingPage() {
    const howItWorksRef = useRef(null)

    return (
        <Container>
            <InnerContainer>
                <Header/>
                <Content>
                    <Welcome howItWorksRef={howItWorksRef} />
                    <HowItWorks howItWorksRef={howItWorksRef} />
                    <Calculator />
                    <Bookmakers />
                    <Pricing />
                    <Reviews />
                    <Faq />
                    <Footer />
                </Content>
            </InnerContainer>
        </Container>
    )
}
