import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Arbitrage } from "../../types/Arbitrage";
import styled from "styled-components";
import ArbitrageComponent from "./Arbitrage";
import background from "./background.jpg"
import Panel from "./panel";
import config from "../../config";
import {fetchWrapper} from "../../utils/fetchWrapper";
import LoaderComponents from "../Loader";
import {WHITE} from "../landingPage/colors";

const Container = styled.div`
    display: flex;
    justify-content: center;
    background-image: url(${background});
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;

    @media only screen and (max-width: 915px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin-top: 100px;
    flex-grow: 1;

    @media only screen and (min-width: 916px) {
        margin-top: 0;
        position: relative;
        left: 240px;
    }
`

const MiddleContainer = styled.div`
    margin: 50px 10px;
    display: flex;
    
    @media only screen and (max-width: 700px) {
        width: 90%;
    }
    
    @media only screen and (max-width: 915px) and (min-width: 701px) {
        justify-content: center;
        width: 90%;
    }
    
    @media only screen and (min-width: 916px) {
        width: 1090px;
        position: relative;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    text-align: center;
`

const NoArbitrage = styled.div`
    font-family: sans-serif;
    color: ${WHITE};
    font-size: 20px;
`

export default function Arbitrages() {
    const [budget, setBudget] = useState(1000)
    const [arbitrages, setArbitrages] = useState<Arbitrage[]>()

    const reload = useCallback(() => {
        const url = `${config.apiUrl}/arbitrages/`

        fetchWrapper(url)
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/auth/sign-in"
                    return Promise.reject("Not authenticated.")
                }

                if (response.status === 403) {
                    // Perhaps Stripe is just delayed.
                    setTimeout(reload, 1000)

                    return Promise.reject("Not authorized.")
                }

                return response.json()
            })
            .then((arbitrages) => setArbitrages(arbitrages))
            .catch(console.error)
    }, [])

    const redirectLink = useMemo(() => {
        return new URLSearchParams(window.location.search).get("redirect")
    }, [])

    // TODO: This is a hot fix for the issue with _blank links.
    useEffect(() => {
        if (redirectLink) {
            window.location.href = redirectLink
        }
    }, []);

    useEffect(() => {
        reload()
    }, [reload])

    return (
        <Container>
            <MiddleContainer>
                <Panel budget={budget} setBudget={setBudget} reload={reload} />
                <InnerContainer>
                    {(!arbitrages || redirectLink) && (
                        <ContentWrapper>
                            <LoaderComponents/>
                        </ContentWrapper>
                    )}
                    {arbitrages?.length === 0 && (
                        <ContentWrapper>
                            <NoArbitrage>
                                <p>Omlouváme se, ale momentálně není k dispozici žádná arbitráž.</p>
                                <br />
                                <p>Zkuste to prosím později.</p>
                            </NoArbitrage>
                        </ContentWrapper>
                    )}
                    {arbitrages?.map((arbitrage) => (
                        <ArbitrageComponent key={arbitrage.id} arbitrage={arbitrage} budget={budget} />
                    ))}
                </InnerContainer>
            </MiddleContainer>
        </Container>
    )
}
