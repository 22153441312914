import {Arbitrage, Odd} from '../../types/Arbitrage'
import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import expandMoreIcon from "../../icons/ffffff/expand_more.png";
import multiplyIcon from "../../icons/858585/clear.png";
import equalsIcon from "../../icons/858585/equals.png";
import openInNewIcon from "../../icons/161817/open_in_new.png";
import {PRIMARY, THEMED_NEUTRAL, WHITE} from "../landingPage/colors";

type Props = {
    odd: Odd
    budget: number
    arbitrage: Arbitrage
}

const Icon = styled.img`
  width: 16px;
  height: 16px;
`

const FormulaIcon = styled(Icon)`
    margin: 0 5px;
`

const BetButtonText = styled.div`
    margin-right: 10px;
`

const OddType = styled.div`
    color: ${PRIMARY};
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    width: 80px;
`

const BookieName = styled.div`
    font-size: 14px;
    border: 1px solid ${WHITE};
    border-radius: 15px;
    margin-right: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 20px;

    &:hover {
        cursor: pointer;
    }
`

const BookieNameText = styled.div`
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const OddValue = styled.div`
    font-size: 14px;
    padding: 0;
    width: 30px;
`

const BetValue = styled.div`
    color: ${PRIMARY};
    font-size: 14px;
    padding: 0;
    width: 50px;
    white-space: nowrap;
`

const ProfitValue = styled.div`
    font-size: 14px;
    padding: 0;
    width: 50px;
    white-space: nowrap;
`

const BetButton = styled.div`
    font-size: 16px;
    padding: 10px;
    background-color: ${PRIMARY};
    border-radius: 50px;
    margin-left: 40px;
    color: ${THEMED_NEUTRAL};
    display: flex;
    align-items: center;
    
    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 700px) {
        margin: 0;
    }
`

const BookieSwitcher = styled.div`
    z-index: 1;
    position: absolute;
    background-color: ${THEMED_NEUTRAL};
    border-radius: 15px;
    top: 35px;
    border: 1px solid ${WHITE};
    padding: 5px 5px 0 5px;
    left: 0;
`

const BookieOption = styled.div`
    margin-bottom: 10px;
    white-space: nowrap;
    padding: 5px;
    border-radius: 10px;

    &:hover {
        color: ${PRIMARY};
    }
`

const Container = styled.div`
    display: flex;

    @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: start;
        margin-bottom: 20px;
    }
`

const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    @media only screen and (max-width: 700px) {
        margin-bottom: 15px;
    }
`

export default function OddComponent({ odd, budget, arbitrage }: Props) {
    const [bookieIndex, setBookieIndex] = useState(0)
    const [bookiesExpanded, setBookiesExpanded] = useState(false)

    const selectedValue = useMemo(() => {
        return odd.values[bookieIndex]
    }, [odd, bookieIndex])

    const openLink = useCallback(() => {
        const link = "?" + new URLSearchParams({ redirect: selectedValue.link }).toString()
        window.open(link, '_blank');
    }, [selectedValue])

    useEffect(() => {
        const handler = () => {
            setBookiesExpanded(false)
        }

        document.addEventListener("click", handler)

        return () => document.removeEventListener("click", handler)
    }, [])

    const computeBet = (odd: number) => {
        return Math.round(budget * arbitrage.payout / 100 / odd)
    }

    const computeProfit = (odd: number) => {
        return Math.round(odd * computeBet(odd))
    }

    const toggleBookies = () => {
        setTimeout(() => {
            setBookiesExpanded(!bookiesExpanded)
        })
    }

    return (
        <Container>
            <Section>
                <OddType key={"OddType-" + odd.odd_type}>{odd.odd_type}</OddType>
                <BookieName key={"BookieName-" + odd.odd_type} onClick={toggleBookies} id={`${arbitrage.id}-${odd.odd_type}`}>
                    <BookieNameText>{selectedValue.bookie_name}</BookieNameText>
                    <Icon src={expandMoreIcon} alt="Expand more" />
                    {bookiesExpanded && (
                        <BookieSwitcher>
                            {odd.values.map((value, index) => (
                                <BookieOption key={value.bookie_name} onClick={() => setBookieIndex(index)}>
                                    {value.bookie_name} ({parseFloat(value.odd).toFixed(2)})
                                </BookieOption>
                            ))}
                        </BookieSwitcher>
                    )}
                </BookieName>
            </Section>
            <Section>
                <OddValue key={"OddValue-" + odd.odd_type}>{parseFloat(selectedValue.odd).toFixed(2)}</OddValue>
                <FormulaIcon src={multiplyIcon} alt="Multiply" />
                <BetValue key={"BetValue-" + odd.odd_type}>{computeBet(parseFloat(selectedValue.odd))} Kč</BetValue>
                <FormulaIcon src={equalsIcon} alt="Equals" />
                <ProfitValue key={"ProfitValue" + odd.odd_type}>{computeProfit(parseFloat(selectedValue.odd))} Kč</ProfitValue>
            </Section>
            <BetButton key={"BetButton" + odd.odd_type} onClick={openLink} >
                <BetButtonText>Vsadit</BetButtonText>
                <Icon src={openInNewIcon} alt="Open in a new tab" />
            </BetButton>
        </Container>
    )
}
