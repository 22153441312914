import styled from "styled-components";

const Card = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 48px;
    border: 1px solid #DDD;
    border-radius: 24px;
    gap: 20px;
    width: 95%;
    max-width: 450px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`

const Title = styled.span`
    font-family: "Kamerik", sans-serif;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
`

const Text = styled.span`
    font-family: "BeVietnamPro", sans-serif;
    color: #DDD;
    font-size: 18px;
    line-height: 30px;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px
`

const Icon = styled.img`
    width: 50px;
    height: 50px;
`

type Props = {
    title: string
    text: string
    icon: string
}

export function Feature(props: Props) {
    const {title, text, icon} = props

    return (
        <Card>
            <Icon src={`/assets/landing/${icon}.png`} alt="Search"/>
            <InnerContainer>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </InnerContainer>
        </Card>
    )
}