import styled from "styled-components";
import step1Image from "./step1.gif"
import step2Image from "./step2.gif"
import step3Image from "./step3.gif"
import {ForwardedRef, forwardRef} from "react";
import {NEUTRAL_300, PRIMARY, WHITE} from "../colors";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${WHITE};
    justify-content: center;
    margin: 0 20px 150px 20px;

    @media only screen and (max-width: 700px) {
        margin-bottom: 50px;
    }
`

const Title = styled.span`
    font-size: 36px;
    font-weight: 700;
    margin: 50px 0;
`

const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 70px;
    max-width: 900px;
    gap: 50px;
`

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    flex: 1;

    @media only screen and (max-width: 700px) {
        width: 100%;
    }
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    @media only screen and (max-width: 700px) {
        display: none;
    }
`

const Image = styled.img`
    width: 100%;
    opacity: 0.5;
`

const TextInnerSection = styled.div`
    //max-width: 400px;
`

const Subtitle = styled.div`
    color: ${PRIMARY};
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
`

const Text   = styled.div`
    color: ${NEUTRAL_300};
    font-size: 16px;
    line-height: 1.3;
`

const Paragraph = styled.div`
    margin-bottom: 20px;
`

const content = [
    {
        subtitle: "1. Analyzujeme stovky kurzových dat",
        image: step1Image,
        text: (
            <Text>
                <Paragraph>
                    Safebets Vám pomůže proměnit sportovní sázení ve stabilní příjem s
                    každodenními bezrizikovými zisky.
                </Paragraph>
                <Paragraph>
                    Zpracováváme více než stovky dat kurzů za sekundu,
                    abychom Vám v reálném čase našli příležitosti pro bezrizikové sázky.
                    Tyto příležitosti vznikají díky velkým rozdílům v kurzech u
                    jednotlivých sázkových kanceláří - jakmile je najdeme,
                    okamžitě je vidíte v našem softwaru.
                </Paragraph>
            </Text>
        ),
    },
    {
        subtitle: "2. Vsadíte u vybraných sázkových kanceláří",
        image: step2Image,
        text: (
            <Text>
                <Paragraph>
                    U každého zápasu vidíte nejen procentuální zhodnocení z daného zápasu,
                    ale také vybrané sázkové kanceláře, které aktuálně nabízejí nejvyšší kurz,
                    díky kterému je bezriziková sázka možná.
                </Paragraph>
                <Paragraph>
                    Přímo v našem systému si vyberete, jak vysokou částku chcete vsadit a
                    na základě ní Vám systém ukáže, v jakém poměru je potřeba vsadit.
                    Poté se už jen prokliknete na stránku dané sázkové kanceláře a
                    vložíte sázku v doporučené výši.
                </Paragraph>
            </Text>
        ),
    },
    {
        subtitle: "3. Vyčkáte na zaručený zisk",
        image: step3Image,
        text: (
            <Text>
                <Paragraph>
                    Nyní už se nemusíte obávat ztraceného tiketu v poslední minutě zápasu -
                    díky arbitrážnímu sázení je totiž Váš zisk jistý.
                    Stačí už tedy jen počkat, obdržet svůj zisk a
                    tento postup znovu zopakovat pro další zaručenou výhru.
                </Paragraph>
                <Paragraph>
                    Využijte náš kalkulátor pro výpočet zisku,
                    který můžete z arbitrážního sázení mít a pojďte do toho s námi!
                </Paragraph>
            </Text>
        )
    }
]

type Props = {}

function HowItWorks(_props: Props, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <Container ref={ref}>
            <Title>Jak to funguje?</Title>
            {content.map(({ subtitle, image, text }, index) => (
                <Section key={index}>
                    {index % 2 === 1 && (
                        <ImageContainer>
                            <Image src={image} alt={`Krok ${index + 1}`} />
                        </ImageContainer>
                    )}
                    <TextSection>
                        <Subtitle>{subtitle}</Subtitle>
                        <span>{text}</span>
                    </TextSection>
                    {index % 2 === 0 && (
                        <ImageContainer>
                            <Image src={image} alt={`Krok ${index + 1}`} />
                        </ImageContainer>
                    )}
                </Section>
            ))}
        </Container>
    )
}

export default forwardRef(HowItWorks)
