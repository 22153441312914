import styled from "styled-components";
import {ForwardedRef, forwardRef, ReactElement} from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    width: 100%;
`

type Props = {
    children: ReactElement
}

function SectionContainerComponent(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const {children} = props

    return (
        <Container ref={ref}>
            {children}
        </Container>
    )
}

export const SectionContainer = forwardRef(SectionContainerComponent)
