import styled from "styled-components";
import {Question} from "./question";
import {SectionContainer} from "../section-container";
import {SectionHeader} from "../section-header";

const QuestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 500px;
    width: 100%;
`

const WideContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 24px;

    @media only screen and (max-width: 1100px) {
        display: none;
    }
`

const NarrowContainer = styled(QuestionsContainer)`
    @media only screen and (min-width: 1101px) {
        display: none;
    }
`

const QUESTIONS = [
    {
        question: "Co je arbitrážní sázení?",
        answer: "Arbitrážní sázení je jediná ověřená metoda, díky které je možné dlouhodobě vydělávat na sportovním sázení. Důvodem je nulové riziko prohry, protože arbitrážní sázení spočívá na hledání kombinace vysokých kurzů, které umožní vsadit na výhru i prohru a zároveň dosáhnout zisku." + "Ptáte se, co se stane když dojde k remíze? Existují i arbitráže, kde vsadíme zároveň na výhru, prohru i remízu. Častěji však hledáme sázky tipu \"Padne 2 a více gólů\" - např. u Fortuny vsadíme, že padne více. U Tipsportu naopak že padne méně. Žádné jiné východisko tak neexistuje a náš zisk je zaručen bez ohledu na konečný výsledek."
    },
    {
        question: "Co díky Safebets získám?",
        answer: "Poskytneme Vám kompletní přístup k naší webové aplikaci, ve které se automaticky zobrazují zápasy, které umožňují uskutečnit bezrizikovou sázku, na základě aktuálních kurzů. Nabízíme Vám tak jedinečnou možnost, jak začít být v sázení dlouhodobě ziskový.",
    },
    {
        question: "Za kolik musím sázet?",
        answer: "Výše vkladu záleží čistě na Vás. Neexistuje žádná minimální částka, za kterou musíte vsadit. Hlavní výhodou arbitrážního sázení je nulové riziko prohry, takže veškeré zisky můžete použít při dalších sázkách, aniž byste se museli obávat ztráty. Můžete tedy klidně začít se stovkami a během několika týdnů se dopracovat na nižší desítky tisíc.",
    },
    {
        question: "Jakého zisku lze dosáhnout?",
        answer: "Denně poskytujeme tipy na zápasy se zhodnocením od 0.5 % výše. Běžně se objevují zápasy se zhodnocením i 10 % a více. V závislosti na počtu měsíčních sázek lze získat i více jak 400 % zhodnocení. Při počátečním kapitálu např. 10.000 Kč jde o zisk 40.000 Kč v prvním měsíci zcela bez rizika." + "Díky nulovému riziku však těchto 50.000 Kč můžete hned v druhém měsíci použít jako větší vklad a tím své zhodnocení dále zvyšovat.",
    },
    {
        question: "Je to legální?",
        answer: "Ano, arbitrážním sázením neporušujete žádný zákon a v České republice je zcela legální.",
    },
    {
        question: "Není to podvod?",
        answer: "Neposkytujeme žádné pochybné sázkové poradenství se \"zaručenými\" tipy na zápasy. Poskytujeme data pro arbitrážní sázení, které je založeno na prosté matematice, bez jakéhokoliv rizika neúspěchu. Nabízíme funkční řešení, díky kterém můžete na sázení skutečně dlouhodobě vydělávat.",
    },
]

export function Faq() {
    return (
        <SectionContainer>
            <>
                <SectionHeader title="FAQ" subtitle="Chcete vědět více? Zde naleznete odpovědi na všechny často kladené otázky"/>
                <NarrowContainer>
                    {QUESTIONS.map(({question, answer}) => (
                        <Question key={question} question={question} answer={answer}/>)
                    )}
                </NarrowContainer>
                <WideContainer>
                    <QuestionsContainer>
                        {QUESTIONS.filter((_, index) => index % 2 === 0).map(({question, answer}) => (
                            <Question key={question} question={question} answer={answer}/>)
                        )}
                    </QuestionsContainer>
                    <QuestionsContainer>
                        {QUESTIONS.filter((_, index) => index % 2 === 1).map(({question, answer}) => (
                            <Question key={question} question={question} answer={answer}/>)
                        )}
                    </QuestionsContainer>
                </WideContainer>
            </>
        </SectionContainer>
    )
}