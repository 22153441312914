import styled from "styled-components";
import {NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, WHITE} from "../landingPage/colors";

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${THEMED_NEUTRAL};
    height: 100%;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    padding: 30px 40px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_600};
    border-radius: 10px;
`

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
`

const Text = styled.div`
    font-size: 16px;
    margin-bottom: 40px;
`

const Link = styled.a`
    font-size: 16px;
    text-decoration: none;
    color: ${PRIMARY};
    font-weight: 700;
`

const Button = styled.div`
    font-size: 14px;
    color: ${THEMED_NEUTRAL};
    border-radius: 10px;
    background-color: ${WHITE};
    padding: 5px 10px;
    font-weight: 700;
    cursor: pointer;
`

export default function Support() {
    return (
        <Container>
            <InnerContainer>
                <Title>Podpora</Title>
                <Text>
                    <span>Prosím napište nám na </span>
                    <Link href="mailto:info@safebets.cz">info@safebets.cz</Link>
                    <span>.</span>
                </Text>
                <Button onClick={() => window.history.back()}>Jít zpět</Button>
            </InnerContainer>
        </Container>
    )
}
