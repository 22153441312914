import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 32px;

    @media only screen and (max-width: 600px) {
        justify-content: end;
    }
`

const Image = styled.img`
    height: 48px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`

const AuthContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    font-family: "Kamerik", sans-serif;
    font-size: 18px;
`

const PrimaryButton = styled.a`
    padding: 18px 24px;
    background-color: rgb(81, 255, 122);
    border-radius: 9999px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    color: #000000;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgb(81, 255, 122);
    }
`

const SecondaryButton = styled.a`
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: rgb(81, 255, 122);
    }
`

export function Header() {
    return (
        <Container>
            <InnerContainer>
                <Image src="/assets/logo.png" alt="Logo"/>
                <AuthContainer>
                    <SecondaryButton href="/auth/sign-in/">Login</SecondaryButton>
                    <PrimaryButton href="/auth/sign-up/">Registrovat</PrimaryButton>
                </AuthContainer>
            </InnerContainer>
        </Container>
    )
}