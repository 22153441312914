import {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import config from "../../config";
import styled from "styled-components";
import Cookies from "js-cookie";
import {
    NEUTRAL_300,
    NEUTRAL_500,
    NEUTRAL_600,
    PRIMARY,
    THEMED_NEUTRAL,
    WARNING_500,
    WHITE
} from "../landingPage/colors";
import * as Sentry from "@sentry/react";
import redirectToApp from "../../utils/redirectToApp";

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${THEMED_NEUTRAL};
    height: 100%;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    padding: 30px 40px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_300};
    border-radius: 10px;
`

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 25px;
    color: ${WHITE};
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: 5px;
`

const Label = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
    color: ${WHITE};
`

const Button = styled.a<{ disabled: boolean }>`
    border: ${({ disabled }) => disabled ? `1px solid ${NEUTRAL_500}` : `1px solid ${PRIMARY}`};
    color: ${({ disabled }) => disabled ? NEUTRAL_500 : PRIMARY};
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    margin: 10px 0 40px 0;

    
    &:hover {
        cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
        background-color: ${({ disabled }) => disabled ? "" : PRIMARY};
        color: ${({ disabled }) => disabled ? NEUTRAL_500 : THEMED_NEUTRAL};
    }
`

const Text = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: ${NEUTRAL_500};
`

const Link = styled.a`
    text-decoration: none;
    color: ${WHITE};
    
    &:hover {
        color: ${PRIMARY};
    }
`

const Error = styled.div`
    font-size: 14px;
    color: ${WARNING_500};
    margin-bottom: 20px;
    font-weight: 700;
`

const FieldError = styled.div`
    font-size: 14px;
    color: ${WARNING_500};
    margin-left: 5px;
`

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${NEUTRAL_600};
    background: none;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    width: 250px;
`

const MiniInput = styled.input`
    color: ${WHITE};
    flex: 1;
    background: none;
    border: 0;
    
    &:focus {
        outline: none;
    }
`

export default function SignUp() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")

    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [passwordConfirmationError, setPasswordConfirmationError] = useState("")
    const [globalError, setGlobalError] = useState("")

    useEffect(() => {
        const session = Cookies.get("session")

        if (session) {
            redirectToApp()
            return
        }
    }, []);

    useEffect(() => {
        if (!email) {
            setEmailError("Povinné")
            return
        }

        if (!email.includes("@") || !email.includes(".")) {
            setEmailError("Chybný formát")
            return
        }

        setEmailError("")
    }, [email])

    useEffect(() => {
        if (!password) {
            setPasswordError("Povinné")
            return
        }

        if (password.length < 8) {
            setPasswordError("Krátké")
            return
        }

        setPasswordError("")
    }, [password])

    useEffect(() => {
        if (password !== passwordConfirmation) {
            setPasswordConfirmationError("Odlišné")
            return
        }

        setPasswordConfirmationError("")
    }, [password, passwordConfirmation])

    const valid = useMemo(() => {
        return !emailError && !passwordError && !passwordConfirmationError
    }, [emailError, passwordError, passwordConfirmationError])

    const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }, [])

    const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }, [])

    const onPasswordConfirmationChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value)
    }, [])

    const submit = useCallback(async () => {
        if (!valid) {
            return
        }

        const url = `${config.apiUrl}/auth/sign-up/`

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        }

        try {
            const response = await fetch(url, options)
            const data = await response.json()

            if (response.ok) {
                Cookies.set("session", data.token, { expires: 365, path: "/", sameSite: "Strict" })
                window.location.href = "/plan"
                return
            }

            setEmailError("")
            setPasswordError("")
            setPasswordConfirmationError("")
            setGlobalError("")

            if (data.detail === "INVALID_EMAIL") {
                setEmailError("Chybný formát")
                return
            }

            if (data.detail === "PASSWORD_TOO_SHORT") {
                setPasswordError("Krátké")
                return
            }

            if (data.detail === "EMAIL_ALREADY_EXISTS") {
                setEmailError("Obsazený")
                return
            }

            Sentry.captureMessage(`Unknown error code ${data.detail} in sign up`)
            setGlobalError("Něco se pokazilo")
        } catch (error) {
            Sentry.captureMessage("Caught exception in sign up")
            Sentry.captureException(error)
            setGlobalError("Něco se pokazilo")
        }
    }, [email, password, valid])

    return (
        <Container>
            <InnerContainer>
                <Title>Registrace</Title>
                {globalError && (
                    <Error>{globalError}</Error>
                )}
                <InputWrapper>
                    <Label>Email</Label>
                    <FieldWrapper>
                        <MiniInput type="text" value={email} onChange={onEmailChange} />
                        <FieldError>{emailError}</FieldError>
                    </FieldWrapper>
                    <Label>Heslo</Label>
                    <FieldWrapper>
                        <MiniInput type="password" value={password} onChange={onPasswordChange} />
                        <FieldError>{passwordError}</FieldError>
                    </FieldWrapper>
                    <Label>
                        <span>Zopakujte heslo</span>
                    </Label>
                    <FieldWrapper>
                        <MiniInput type="password" value={passwordConfirmation} onChange={onPasswordConfirmationChange} />
                        <FieldError>{passwordConfirmationError}</FieldError>
                    </FieldWrapper>
                </InputWrapper>
                <Button onClick={submit} disabled={!valid}>Registrovat se</Button>
                <Text>
                    <span>Již máte učet? </span>
                    <Link href="/auth/sign-in">Přihlašte se</Link>
                </Text>
            </InnerContainer>
        </Container>
    )
}
