import MainSection from "./mainSection";
import styled from "styled-components";
import Calculator from "./calculator";
import HowItWorks from "./howItWorks";
import Screenshot from "./screenshot";
import Faq from "./faq";
import {useRef} from "react";
import {BLACK} from "./colors";
import Pricing from "./pricing";

const Container = styled.div`
    font-family: Montserrat, sans-serif;
    overflow-x: hidden;
    background-color: ${BLACK};
`

export default function LandingPage() {
    const calculatorRef = useRef(null)
    const howItWorksRef = useRef(null)
    const screenshotsRef = useRef(null)
    const pricingRef = useRef(null)
    const faqRef = useRef(null)

        return (
        <Container>
            <MainSection
                calculatorRef={calculatorRef}
                howItWorksRef={howItWorksRef}
                pricingRef={pricingRef}
                screenshotsRef={screenshotsRef}
                faqRef={faqRef}
            />
            <HowItWorks ref={howItWorksRef} />
            <Calculator ref={calculatorRef} />
            <Pricing ref={pricingRef} />
            <Screenshot ref={screenshotsRef} />
            <Faq ref={faqRef} />
        </Container>
    )
}
