import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(81, 255, 122);
    border-radius: 48px;
    padding: 128px 64px;
    margin: 64px;
    gap: 64px;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: start;
        gap: 32px;
        padding: 64px;
    }

    @media only screen and (max-width: 600px) {
        margin: 0;
        padding: 64px 32px;
    }
`

const Title = styled.div`
    font-family: "Kamerik", sans-serif;
    font-size: 38px;
    font-weight: 700;
    max-width: 500px;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    font-family: "Kamerik", sans-serif;
    font-size: 18px;
    white-space: nowrap;

    @media only screen and (max-width: 1000px) {
        align-items: start;
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`

const PrimaryButton = styled.a`
    color: #FFF;
    padding: 18px 24px;
    background-color: rgb(30, 30, 30);
    border-radius: 9999px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    text-decoration: none;

    &:hover {
        transform: scale(1.1);
    }
`

const SecondaryButton = styled.a`
    padding: 18px 24px;
    border: 1px solid rgb(30, 30, 30);
    border-radius: 9999px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    color: #000;

    &:hover {
        transform: scale(1.1);
    }
`

export function Footer() {
    return (
        <Container>
            <Card>
                <Title>Nyní víte vše, tak neváhejte a vyzkoušejte nejlepší sázkový software!</Title>
                <ButtonContainer>
                    <PrimaryButton href="/auth/sign-up/">Registrovat</PrimaryButton>
                    <SecondaryButton href="mailto:info@safebets.cz">Napište nám</SecondaryButton>
                </ButtonContainer>
            </Card>
        </Container>

    )
}