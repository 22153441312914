import styled from "styled-components";
import {useMemo, useState} from "react";
import SliderComponent from "./Slider";
import {formatMoney} from "../../../utils/formattors";
import {SectionContainer} from "../section-container";
import {SectionHeader} from "../section-header";

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    max-width: 600px;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    border: 2px solid #333;
    padding: 48px;
    border-radius: 24px;
    gap: 48px;
    margin: 0 10px;
`

const Profit = styled.div`
    font-family: "BeVietnamPro", sans-serif;
    background-color: #51FF7A;
    border-radius: 10px;
    padding: 20px;
    color: #000;
    font-size: 24px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    @media only screen and (max-width: 550px) {
        flex-direction: column;
    }
`

const SliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const ProfitValue = styled.div`
    font-size: 32px;
    font-weight: 700;
`

export function Calculator() {
    const [averageOdd, setAverageOdd] = useState(3)
    const [betsPerDay, setBetsPerDay] = useState(5)
    const [budget, setBudget] = useState(20000)

    const monthlyProfit = useMemo(() => {
        return Math.round(budget * (averageOdd / 100) * (betsPerDay * 30))
    }, [averageOdd, betsPerDay, budget])

    const sliders = useMemo(() => [
        {
            value: averageOdd,
            setter: setAverageOdd,
            min: 1,
            max: 15,
            step: 1,
            label: "Průměrný zisk na zápas",
            unit: "%",
        },
        {
            value: betsPerDay,
            setter: setBetsPerDay,
            min: 1,
            max: 10,
            step: 1,
            label: "Sázek denně",
            unit: "",
        },
        {
            value: budget,
            setter: setBudget,
            min: 1000,
            max: 100000,
            step: 1000,
            label: "Rozpočet",
            unit: " Kč",
        }
    ], [averageOdd, betsPerDay, budget])

    return (
        <SectionContainer>
            <>
                <SectionHeader
                    title="Spočítejte si zisk"
                    subtitle="Vytvořili jsme odhad zisku, který vám umožní zjistit, kolik můžete potenciálně vydělat za měsíc v závislosti na vašem bankrollu a počtu sázek, které denně podáte."
                />
                <InnerContainer>
                    <Content>
                        <SliderWrapper>
                            {sliders.map((slider) => (
                                <SliderComponent key={slider.label} slider={slider}/>
                            ))}
                        </SliderWrapper>
                        <Profit>
                            <div>Měsíční zisk</div>
                            <ProfitValue>{formatMoney(monthlyProfit)} Kč</ProfitValue>
                        </Profit>
                    </Content>
                </InnerContainer>
            </>
        </SectionContainer>
    )
}
