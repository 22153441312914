import React from "react";
import {Arbitrage} from "../../types/Arbitrage";
import styled from "styled-components";
import OddComponent from "./Odd";

type Props = {
    arbitrage: Arbitrage
    budget: number
}

const TableRow = styled.div`
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export default function Odds({ arbitrage, budget }: Props) {
    return (
        <div>
            {arbitrage.odds.map((odd) => (
                <TableRow key={odd.odd_type}>
                    <OddComponent odd={odd} budget={budget} arbitrage={arbitrage} />
                </TableRow>
            ))}
        </div>
    )
}
