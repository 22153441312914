import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {NEUTRAL_500, NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, WARNING_500, WHITE} from "./landingPage/colors";
import {ChangeEvent, useCallback, useState} from "react";
import config from "../config";

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${THEMED_NEUTRAL};
    height: 100%;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    padding: 30px 40px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_600};
    border-radius: 10px;
`

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 25px;
    color: ${WHITE};
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: 5px;
`

const Label = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
    color: ${WHITE};
`

const Button = styled.a`
    border: 1px solid ${PRIMARY};
    color: ${PRIMARY};
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    margin: 10px 0 0 0;

    
    &:hover {
        cursor: pointer;
        background-color: ${PRIMARY};
        color: ${THEMED_NEUTRAL};
    }
`

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${NEUTRAL_600};
    background: none;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    width: 250px;
`

const MiniInput = styled.input`
    color: ${WHITE};
    flex: 1;
    background: none;
    border: 0;
    
    &:focus {
        outline: none;
    }
`

export default function PasswordReset() {
    const { search } = useLocation()

    const [password, setPassword] = useState("")

    const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value)
    }, [])

    const submit = useCallback(async () => {
      const params = new URLSearchParams(search)
      const key = params.get("key") || ""
      const email = params.get("email") || ""

      const url = `${config.apiUrl}/auth/reset-password/`

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, key }),
      }

      const response = await fetch(url, options)

      if (response.ok) {
        window.location.href = "/auth/sign-in"
      }
    }, [password])

  return (
    <Container>
      <InnerContainer>
        <Title>Změna hesla</Title>
        <InputWrapper>
          <Label>Nové heslo</Label>
          <FieldWrapper>
            <MiniInput type="password" value={password} onChange={onPasswordChange} />
          </FieldWrapper>
        </InputWrapper>
        <Button onClick={submit}>Změnit</Button>
      </InnerContainer>
    </Container>
  )
}