import styled from "styled-components";
import {RefObject, useCallback} from "react";
import {PRIMARY, WHITE} from "../colors";
import logo from "./logo.png";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin: 0 30px;
    height: 40px;

    @media only screen and (max-width: 500px) {
        justify-content: end;
    }
`

const LeftPanel = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 500px) {
        display: none;
    }
`

const NavigationPanel = styled.div`
    @media only screen and (max-width: 1100px) {
        display: none;
    }
`

const Link = styled.a`
    margin-right: 40px;
    color: ${WHITE};
    font-size: 18px;
    text-decoration: none;
    
    &:hover {
        color: ${PRIMARY};
    }
`

const Button = styled.a`
    position: relative;
    border: 2px solid ${PRIMARY};
    color: ${PRIMARY};
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    
    &:hover {
        box-shadow: 0 0 10px ${PRIMARY};
    }
`

const Logo = styled.img`
    height: 100%;
    margin-right: 40px;
`

type Props = {
    calculatorRef: RefObject<HTMLDivElement>
    howItWorksRef: RefObject<HTMLDivElement>
    pricingRef: RefObject<HTMLDivElement>
    screenshotsRef: RefObject<HTMLDivElement>
    faqRef: RefObject<HTMLDivElement>
}

export default function TopPanel({ calculatorRef, howItWorksRef, pricingRef, screenshotsRef, faqRef }: Props) {
    const scrollTo = useCallback((ref: RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
    }, [])

    return (
        <Container>
            <LeftPanel>
                <Logo src={logo} alt="Logo" />
                <NavigationPanel>
                    <Link href="#how-it-works" onClick={() => scrollTo(howItWorksRef)}>Princip</Link>
                    <Link href="#calculator" onClick={() => scrollTo(calculatorRef)}>Kalkulačka</Link>
                    <Link href="#pricing" onClick={() => scrollTo(pricingRef)}>Cena</Link>
                    <Link href="#screenshots" onClick={() => scrollTo(screenshotsRef)}>O aplikaci</Link>
                    <Link href="#faq" onClick={() => scrollTo(faqRef)}>FAQ</Link>
                </NavigationPanel>
            </LeftPanel>
            <div>
                <Link href="/auth/sign-in/">Přihlásit</Link>
                <Button href="/auth/sign-up/">Registrovat</Button>
            </div>
        </Container>
    )
}
