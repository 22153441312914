import styled from "styled-components";
import chartImage from "./image.gif";
import {forwardRef, ForwardedRef, useMemo, useState} from "react";
import {BLACK, NEUTRAL_300, PRIMARY, WHITE} from "../colors";
import SliderComponent from "./Slider";
import {formatMoney} from "../../../utils/formattors";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 300px;

    @media only screen and (max-width: 700px) {
        margin-bottom: 0;
    }
`

const Image = styled.img`
    width: 35%;
    max-width: 500px;
    opacity: 0.5;
    margin-right: 15%;

    @media only screen and (max-width: 700px) {
        display: none;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 600px;
`

const SubTitle = styled.span`
    color: ${PRIMARY};
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
`

const ProfitWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`

const Profit = styled.div`
    background-color: ${PRIMARY};
    border-radius: 10px;
    padding: 20px;
    color: ${BLACK};
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    box-shadow: 0 0 30px ${PRIMARY};
    
    @media only screen and (min-width: 701px) {
        min-width: 300px;
    }
    
    @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`

const ProfitValue = styled.div`
    font-size: 32px;
    font-weight: 700;
`

const Text = styled.div`
    color: ${NEUTRAL_300};
    font-size: 16px;
    line-height: 1.3;
    max-width: 600px;
    margin-top: 30px;
    margin-bottom: 80px;
    text-align: center;
    padding: 0 30px;
`

const Title = styled.span`
    color: ${WHITE};
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
`

const SectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
`

// @ts-ignore
function Calculator(_, ref: ForwardedRef<HTMLDivElement>) {
    const [averageOdd, setAverageOdd] = useState(3)
    const [betsPerDay, setBetsPerDay] = useState(5)
    const [budget, setBudget] = useState(20000)

    const monthlyProfit = useMemo(() => {
        return Math.round(budget * (averageOdd / 100) * (betsPerDay * 30))
    }, [averageOdd, betsPerDay, budget])

    const sliders = useMemo(() => [
        {
            value: averageOdd,
            setter: setAverageOdd,
            min: 1,
            max: 30,
            step: 1,
            label: "Průměrný zisk na zápas",
            unit: "%",
        },
        {
            value: betsPerDay,
            setter: setBetsPerDay,
            min: 1,
            max: 15,
            step: 1,
            label: "Sázek denně",
            unit: "",
        },
        {
            value: budget,
            setter: setBudget,
            min: 1000,
            max: 100000,
            step: 1000,
            label: "Rozpočet",
            unit: " Kč",
        }
    ], [averageOdd, betsPerDay, budget])

    return (
        <Container ref={ref}>
            <Title>Spočítejte si zisk</Title>
            <Text>
                Na rozdíl od tradičního sázení může arbitrážní sázení při správném postupu
                přinést předvídatelný měsíční zisk bez rizika.
                Vytvořili jsme odhad zisku, který vám umožní zjistit,
                kolik můžete potenciálně vydělat za měsíc v závislosti na vašem bankrollu a počtu sázek,
                které denně podáte.
            </Text>
            <SectionContainer>
                <Image src={chartImage} alt="Chart" />
                <Content>
                    <SubTitle>Kalkulačka</SubTitle>
                    {sliders.map((slider) => (
                        <SliderComponent key={slider.label} slider={slider} />
                    ))}
                    <ProfitWrapper>
                        <Profit>
                            <ProfitValue>{formatMoney(monthlyProfit)} Kč</ProfitValue>
                            <div>měsíčně</div>
                        </Profit>
                    </ProfitWrapper>
                </Content>
            </SectionContainer>
        </Container>
    )
}

export default forwardRef(Calculator)
