// export const ENV = "development"
export const ENV = "production"

const config = {
  development: {
    apiUrl: "http://localhost:8000/api",
  },
  production: {
    apiUrl: "https://safebets.cz/api",
  },
}

export default config[ENV]
