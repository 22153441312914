import styled from "styled-components";
import {ForwardedRef, forwardRef, useState} from "react";
import expandMoreIcon from "../../../icons/ffffff/expand_more.png";
import background from "./background.jpg";
import {NEUTRAL_300, NEUTRAL_600, WHITE} from "../colors";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${WHITE};
    justify-content: center;
    overflow: hidden;
`

const Background = styled.div`
    position: absolute;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const NestedContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const InnerContainer = styled.div`
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 50px;
`

const Title = styled.div`
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
`

const Subtitle = styled.div`
    font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &:hover {
        cursor: pointer;
    }
`

const Text = styled.div`
    margin: 10px 10px 30px 42px;
    color: ${NEUTRAL_300};
    line-height: 1.3;
`

const Paragraph = styled.div`
    margin-bottom: 10px;
`

const ParagraphTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 10px 0;
`

const Icon = styled.img<{ expanded: number }>`
    width: 32px;
    height: 32px;
    margin-right: 10px;
    ${({ expanded }) => expanded === 1 && "transform: rotate(-90deg);"}
`

const Line = styled.hr`
    width: 700px;
    border: 1px solid ${NEUTRAL_600};
    margin: 20px 0;
    
    @media only screen and (max-width: 700px) {
        width: 100vw;
    }
`

const Question = styled.div`
    padding: 0 10px;
`

const CONTENT = [
    {
        question: "Co je arbitrážní sázení?",
        answer: (
            <Text>
                <Paragraph>
                    Arbitrážní sázení je proces, který využivá matematickou výhodu a zaručuje zisk,
                    bez ohledu na výsledek zápasu.
                    Stačí vsadit na všechny možné výsledky zápasu u rozdílných sázkových kanceláří
                    v takovém poměru, aby kterýkoliv výsledek pokryl zbylé sázky a
                    k tomu navíc přinesl dodatečný zisk.
                </Paragraph>
                <ParagraphTitle>Funguje to takto</ParagraphTitle>
                <Paragraph>
                    Řekněme, že se hraje tenisový zápas mezi Novakem Džokovičem a Rafaelem Nadalem.
                </Paragraph>
                <Paragraph>
                    Tipsport nabízí kurz 2,50 na výhru Novaka Džokoviče,
                    zatímco Fortuna nabízí kurz 2,70 na výhru Rafaela Nadala.
                    Vsazením určité částky na Novaka Džokoviče u Tisport a
                    vypočtené částky na Rafaela Nadala u Fortuny si sázkař zajistí zisk bez ohledu na to,
                    kdo zápas vyhraje.
                </Paragraph>
                <Paragraph>
                    V tomto příkladu, pokud sázkař vsadí 1000 korun na Novaka Džokoviče
                    (s kurzem 2,50 u Tipsportu) a 1000 korun na Rafaela Nadala (s kurzem 2,70 u Fortuny),
                    činí celková investice (vklad) 2000 korun.
                </Paragraph>
                <ParagraphTitle>Uvažujme nyní dva scénáře:</ParagraphTitle>
                <Paragraph>
                    Pokud Novak Džokovič vyhraje,
                    sázející obdrží od Tipsportu výplatu 2500 korun (2,50 x 1000 korun),
                    což znamená zisk 500 korun (2500 korun - Váš celkový vklad 2000 za obě sázky).
                    Pokud vyhraje Rafael Nadal, obdrží sázející od Fortuny výplatu 2700 korun
                    (2,70 x 1000 korun), což znamená zisk 700 korun
                    (2700 korun - Váš celkový vklad 2000 za obě sázky).
                </Paragraph>
                <Paragraph>
                    Možná se ptáte, co když zápas skončí remízou? V případě,
                    že by šlo například o fotbalový či hokejový zápas,
                    byla by částka 2000,- rozdělena na tři části, poměrově v vypsaným kurzům.
                    Vše za Váš však již spočítá náš software.
                </Paragraph>
                <Paragraph>
                    Naše služba tedy spočívá ve schopnosti najít zápasy,
                    u kterých vypsané kurzy umožňují vsadit právě jako v případu popsaném výše.
                </Paragraph>
                <Paragraph>
                    Se službou Safebets budete moci denně sledovat desítky příležitostí k arbitrážnímu sázení,
                    se zaručenými zisky v rozmezíod 1 %, 3 %, 5 %, 10 %, 15 %
                    a někdy i 20 % v řadě různých sportů.
                </Paragraph>
            </Text>
        ),
    },
    {
        question: "Kolik můžu se Safebets vydělat?",
        answer: (
            <Text>
                Denně poskytujeme informace o arbitrážních sázkách od 1.5 % výše.
                Často se však objevují příležitosti i přes 10 % zaručeného zisku.
                V závislosti na počtu měsíčních sázek lze získat i více jak 850 % zhodnocení.
                Při počátečním kapitálu např. 10.000 Kč jde o zisk 85.000 Kč v prvním měsíci zcela bez rizika.
            </Text>
        ),
    },
    {
        question: "Co za své členství získám?",
        answer: (
            <Text>
                V rámci měsíčního členství získáte nepřetržitý přístup k aktuálnímu přehledu všech zápasů,
                u nichž lze vsadit zcela bez rizika, včetně spočítaných poměrů v jakých vsadit a
                přímými prokliky na vybrané sázkové kanceláře.
            </Text>
        ),
    },
    {
        question: "Je to legální?",
        answer: (
            <Text>
                100% Ano - arbitrážním sázením neporušujete žádný zákon.
            </Text>
        ),
    },
    {
        question: "Není to podvod?",
        answer: (
            <Text>
                Určitě ne - neposkytujeme žádné pochybné sázkové poradenství s nejistým výsledkem.
                Poskytujeme data pro arbitrážní sázení, které je prostá matematika,
                bez jakéhokoliv rizika neúspěchu.
            </Text>
        ),
    },
    {
        question: "Kolik potřebuji peněz?",
        answer: (
            <Text>
                Arbitrážní sázka nemá minimální limit, záleží tak jen pouze na Vás,kolik chcete vydělat.
                Pro přesnější výpočet využijte naši webovou kalkulačku.
            </Text>
        ),
    },
    {
        question: "Potřebuji nějaké předchozí zkušenosti se sázením?",
        answer: (
            <Text>
                Žádné předchozí zkušenosti se sázením nejsou potřeba -
                náš software Vám poskytne přehled vhodných zápasů, spočítá poměr rozdělení sázky.
                Na Vás je pouze vsadit podle instrukcí u vybraných sázkových kanceláří podle naší analýzy a
                následně vyčkat na svůj zisk.
            </Text>
        ),
    },
]

type Props = {}

type QuestionAnswer = {
    question: string,
    answer: JSX.Element,
    expanded: boolean,
}

function Faq(_props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const [content, setContent] = useState<QuestionAnswer[]>(
        CONTENT.map(({ question, answer }) => ({ question, answer, expanded: false }))
    )

    return (
        <Container ref={ref}>
            <Background />
            <NestedContainer>
                <Title>Často kladené otázky</Title>
                <InnerContainer>
                    {content.map(({ question, answer, expanded}, index) => (
                        <Question key={index}>
                            {index === 0 && <Line />}
                            <Subtitle onClick={() => {
                                const newContent = [...content]
                                newContent[index].expanded = !newContent[index].expanded
                                setContent(newContent)
                            }}>
                                <Icon src={expandMoreIcon} expanded={expanded ? 1 : 0} />
                                <span>{question}</span>
                            </Subtitle>
                            {expanded && answer}
                            <Line />
                        </Question>
                    ))}
                </InnerContainer>
            </NestedContainer>
        </Container>
    )
}

export default forwardRef(Faq)
