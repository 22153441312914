import React, {RefObject, useCallback} from "react";
import styled from "styled-components";
import {BLACK, NEUTRAL_300, PRIMARY, WHITE} from "../colors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 25vh;
    margin-bottom: 50px;
    padding: 0 20px;

    @media only screen and (max-width: 700px) {
        margin-top: 5vh;
    }
    
    @media only screen and (min-height: 1000px) {
        margin-top: 35vh;
    }
`

const InnerContainer = styled.div`
    max-width: 700px;
    text-align: center;
`

const Title = styled.div`
    color: ${WHITE};
    font-size: 44px;
    margin-bottom: 25px;
    font-weight: 700;
`

const Subtitle = styled.div`
    color: ${WHITE};
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`

const Text = styled.div`
    color: ${NEUTRAL_300};
    font-size: 20px;
    margin-bottom: 40px;
    font-weight: 400;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`

const PrimaryButton = styled.a`
    position: relative;
    background-color: ${PRIMARY};
    color: ${BLACK};
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    
    &:hover {
        box-shadow: 0 0 10px ${PRIMARY}
    }
`

const Button = styled.a`
    position: relative;
    border: 2px solid ${PRIMARY};
    color: ${PRIMARY};
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    
    &:hover {
        box-shadow: 0 0 10px ${PRIMARY};
    }
`

const TextBreak = styled.br`
    @media only screen and (max-width: 700px) {
        display: none;
    }
`

type Props = {
    howItWorksRef: RefObject<HTMLDivElement>
}

export default function Content({ howItWorksRef }: Props) {
    const scrollTo = useCallback(() => {
        howItWorksRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [howItWorksRef])

    return (
        <Container>
            <InnerContainer>
                <Title>
                    Hledáme a poskytujeme data <TextBreak />
                    pro sázení bez rizika
                </Title>
                <Text>
                    Safebets pomáhá každý den stovkám sázkařů, <TextBreak />
                    získat data pro arbitrážní sázení, při kterém <TextBreak />
                    je zaručen zisk bez ohledu na výsledek.
                </Text>
                <Subtitle>Staň se také členem této komunity</Subtitle>
                <ButtonContainer>
                    <PrimaryButton href="/auth/sign-up/">Registrace zdarma</PrimaryButton>
                    <Button href="#how-it-works" onClick={scrollTo}>Zjistit více</Button>
                </ButtonContainer>
            </InnerContainer>
        </Container>
    )
}
