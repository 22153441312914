import screenshot from "./screenshot.jpg";
import styled from "styled-components";
import {ForwardedRef, forwardRef} from "react";
import {WHITE} from "../colors";

const Container = styled.div`
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 300px;
`

const Title = styled.span`
    position: absolute;
    color: ${WHITE};
    font-size: 36px;
    font-weight: 700;
    margin: 50px 0;
    top: 25%;
    white-space: nowrap;

    @media only screen and (max-width: 1000px) {
        top: 20%;
    }
`

const Image = styled.img`
    width: 150%;
    max-width: 1200px;
`

type Props = {}

function Screenshot(_props: Props, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <Container ref={ref}>
            <Title>Ukázka aplikace</Title>
            <Image src={screenshot} alt="Sceenshot" />
        </Container>
    )
}

export default forwardRef(Screenshot)
