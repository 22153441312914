export const WHITE = "#FFFFFF"
export const BLACK = "#000000"
export const PRIMARY = "#51FF7A"

export const NEUTRAL_600 = "#333333"
export const NEUTRAL_500 = "#888888"
export const NEUTRAL_300 = "#CCCCCC"
export const WARNING_500 = "#FF7373"

export const THEMED_NEUTRAL = "#161817"
export const TRANSPARENT_THEMED_NEUTRAL = "#161817DD"
