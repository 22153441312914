import styled, {keyframes} from "styled-components";
import screenshot from "./screenshot.png";
import React, {RefObject, useCallback} from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 72px;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    gap: 24px;
    padding: 0 10px;
`

const titleAppear = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    20% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Title = styled.span`
    font-family: "Kamerik", serif;
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    color: #FFFFFF;
    text-align: center;
    animation: ${titleAppear} 1s ease-in-out forwards;
`

const appear = keyframes`
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

const Subtitle = styled.span`
    font-family: "BeVietnamPro", sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: rgb(177, 177, 177);
    text-align: center;
    animation: ${appear} 1.5s ease-in-out forwards;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    font-family: "Kamerik", sans-serif;
    font-size: 18px;
    white-space: nowrap;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`

const PrimaryButton = styled.a`
    padding: 18px 24px;
    background-color: rgb(81, 255, 122);
    border-radius: 9999px;
    cursor: pointer;
    animation: ${appear} 1.5s ease-in-out forwards;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    color: #000000;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgb(81, 255, 122);
    }
`

const SecondaryButton = styled.a`
    padding: 18px 24px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 9999px;
    cursor: pointer;
    animation: ${appear} 1.5s ease-in-out forwards;
    text-decoration: none;
    
    &:hover {
        color: rgb(81, 255, 122);
        border: 1px solid rgb(81, 255, 122);
    }
`

const screenshotAppear = keyframes`
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: scale(0.95);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

const Screenshot = styled.img`
    width: 95%;
    max-width: 850px;
    border: 1px solid #333;
    border-radius: 24px;
    animation: ${screenshotAppear} 2s ease-in-out forwards;
`

type Props = {
    howItWorksRef: RefObject<HTMLDivElement>
}

export function Welcome({ howItWorksRef }: Props) {
    const scrollTo = useCallback(() => {
        howItWorksRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [howItWorksRef])

    return (
        <Container>
            <InnerContainer>
                <TitleWrapper>
                    <Title>Získejte data pro sázení bez rizika</Title>
                    <Subtitle>Safebets pomáhá každý den stovkám sázkařů - poskytujeme data pro arbitrážní sázení, které zaručuje zisk bez ohledu na výsledek zápasu.</Subtitle>
                </TitleWrapper>
                <ButtonContainer>
                    <PrimaryButton href="auth/sign-up/">Vyzkoušet 3 dny zcela zdarma</PrimaryButton>
                    <SecondaryButton href="#how-it-works" onClick={scrollTo}>Zjistit více</SecondaryButton>
                </ButtonContainer>
            </InnerContainer>
            <Screenshot src={screenshot} alt="Screenshot" />
        </Container>
    )
}