import React, {useEffect, useState} from "react";
import {fetchWrapper} from "../../../utils/fetchWrapper";
import config from "../../../config";
import lodash from "lodash"
import {DateTime} from "luxon"
import styled from "styled-components";
import {NEUTRAL_300} from "../../landingPage/colors";
import {EventModal, ModalState} from "./EventModal";
import {TeamCell} from "./TeamCell";

type SportEvent = {
    raw_home_team: string
    raw_away_team: string
    home_team: string
    away_team: string
    event_time: string
    source: string
    raw_league: string
}

type EventGroup = {
    eventId: string
    events: SportEvent[]
}

type TimeGroup = {
    time: string
    eventGroups: EventGroup[]
}

export const BOOKMAKERS = [
    "fortuna",
    "tipsport",
    "betano",
    "merkurxtip",
    "synottip",
    "sazka",
    "forbet"
]

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
`

const TimeGroup = styled.div`
    display: flex;
    flex-direction: column;
`

const EventGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const Time = styled.div`
    font-weight: 600;
    padding: 10px 5px;
    background-color: ${NEUTRAL_300};
`

const BookmakerCell = styled.td`
    width: 120px;
    padding: 5px;
    border: 1px solid ${NEUTRAL_300};
    font-size: 14px;
`

const TableHeader = styled.thead`
    background-color: ${NEUTRAL_300};
`

const HeaderCell = styled.td`
    text-align: left;
    padding: 5px;
    border: 1px solid ${NEUTRAL_300};
`

export function AdminEvents() {
    const [events, setEvents] = useState<TimeGroup[]>([])

    const [modalState, setModalState] = useState<ModalState>({
        showModal: false,
        team: "",
    })

    const getNewEventId = (event: SportEvent) => {
        const {event_time, home_team, away_team} = event

        if (!home_team || !away_team) {
            return null
        }

        return [event_time, home_team, away_team].join("-")
    }

    const fetchEvents = async () => {
        const response = await fetchWrapper(`${config.apiUrl}/admin/events`)
        const allEvents = await response.json()
        const eventsGroupedByTime = lodash.groupBy(allEvents as SportEvent[], "event_time")

        const events = lodash
            .orderBy(Object.entries(eventsGroupedByTime), ([time]) => time)
            .map(([time, events]) => {
                const eventsGroupedById = lodash.groupBy(events, getNewEventId)

                const eventGroups = lodash
                    .orderBy(Object.entries(eventsGroupedById), ([eventId]) => eventId)
                    .map(([eventId, events]) => ({
                        eventId,
                        events,
                    }))

                return {time, eventGroups}
            })

        setEvents(events)
    }

    const formatTime = (isoTime: string) => {
        return DateTime.fromISO(isoTime).toFormat("MMM d T")
    }

    useEffect(() => {
        fetchEvents().catch(console.error)
    }, []);

    return (
        <Container>
            {events.map(({time, eventGroups}) => (
                <TimeGroup key={time}>
                    <Time>{formatTime(time)}</Time>
                    <EventGroupContainer>
                        <table>
                            <TableHeader>
                                <HeaderCell>Home team</HeaderCell>
                                <HeaderCell>Away team</HeaderCell>
                                {BOOKMAKERS.map((bookmaker) => (
                                    <HeaderCell key={`${time}-${bookmaker}`}>{bookmaker}</HeaderCell>
                                ))}
                            </TableHeader>
                            <tbody>
                            {eventGroups.map(({eventId, events}) => {
                                const {
                                    home_team: homeTeam,
                                    away_team: awayTeam,
                                } = events.at(0)!

                                return (
                                    <tr key={eventId}>
                                        <TeamCell
                                            setModalState={setModalState}
                                            team={homeTeam}
                                        />
                                        <TeamCell
                                            setModalState={setModalState}
                                            team={awayTeam}
                                        />
                                        {BOOKMAKERS.map((bookmaker) => (
                                            <BookmakerCell key={`${eventId}_${bookmaker}`}>
                                                {events.find(({source}) => source === bookmaker)?.raw_league}
                                            </BookmakerCell>
                                        ))}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </EventGroupContainer>
                </TimeGroup>
            ))}
            {modalState.showModal && (
                <EventModal modalState={modalState} setModalState={setModalState}/>
            )}
        </Container>
    )
}
