import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
`

const Title = styled.span`
    font-size: 32px;
    margin-bottom: 20px;
`

const Link = styled.a`
    font-size: 20px;
    margin-bottom: 20px;
`

export function AdminHome() {
    return (
        <Container>
            <Title>Admin</Title>
            <Link href={"/admin/events"}>Events</Link>
        </Container>
    )
}
