import { Arbitrage } from "../../types/Arbitrage"
import styled from "styled-components";
import React, {useMemo} from "react";
import Odds from "./Odds";
import {
    NEUTRAL_500,
    NEUTRAL_600,
    PRIMARY,
    THEMED_NEUTRAL,
    TRANSPARENT_THEMED_NEUTRAL,
    WHITE
} from "../landingPage/colors";

type Props = {
    arbitrage: Arbitrage
    budget: number
}

const Card = styled.div`
    background-color: ${TRANSPARENT_THEMED_NEUTRAL};
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px 20px 0 20px;
    margin-bottom: 30px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_600};

    @media only screen and (max-width: 700px) {
        padding-bottom: 20px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const TitleRowWrapper = styled(Row)`
    justify-content: space-between;
    align-items: start;
`

const TitleRow = styled(Row)`
    align-items: end;
    color: ${PRIMARY};
    margin-bottom: 20px;
`

const TitleValue = styled.div`
    font-size: 40px;
    margin-right: 10px;
    font-weight: 700;
    text-shadow: 0 0 5px ${PRIMARY};
`

const TitleText = styled.div`
    font-size: 20px;
`

const Timestamp = styled.span`
    font-size: 14px;
    white-space: nowrap;
`

const TopTimestamp = styled(Timestamp)`
    @media only screen and (max-width: 700px) {
        display: none;
    }
`

const BottomTimestamp = styled(Timestamp)`
    @media only screen and (min-width: 701px) {
        display: none;
    }
`

const TimestampText = styled.span`
    color: ${NEUTRAL_500};
`

const TextRow = styled.div`
    @media only screen and (max-width: 700px) {
        margin-bottom: 5px;
    }
`

const CategoryRow = styled(Row)`
    align-items: center;
    margin-bottom: 50px;

    @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: start;
        margin-bottom: 20px;
    }
`

const BetTypeRow = styled(Row)`
    align-items: center;
    margin-bottom: 20px;
    color: ${NEUTRAL_500};

    @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: start;
        margin-bottom: 40px;
    }
`

const Dot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 10px;

    @media only screen and (max-width: 700px) {
        display: none;
    }
`

const WhiteDot = styled(Dot)`
    background-color: ${WHITE};
`

const GrayDot = styled(Dot)`
    background-color: ${NEUTRAL_500};
`

export default function ArbitrageComponent({ arbitrage, budget }: Props) {
    const profit = useMemo(() => (arbitrage.payout - 100).toFixed(2), [arbitrage])

    const generatedAt = useMemo(() => {
        const date = new Date(arbitrage.generated_at)
        const hours = date.getHours().toString().padStart(2, "0")
        const minutes = date.getMinutes().toString().padStart(2, "0")
        const seconds = date.getSeconds().toString().padStart(2, "0")

        return `${hours}:${minutes}:${seconds}`
    }, [arbitrage])

    return (
        <Card>
            <TitleRowWrapper>
                <TitleRow>
                    <TitleValue>{profit}%</TitleValue>
                    <TitleText>jistý zisk</TitleText>
                </TitleRow>
                <TopTimestamp>
                    <TimestampText>Aktualizováno v </TimestampText>
                    {generatedAt}
                </TopTimestamp>
            </TitleRowWrapper>
            <CategoryRow>
                <TextRow>{arbitrage.sport_name}</TextRow>
                {arbitrage.category_name && <WhiteDot />}
                {arbitrage.category_name && <TextRow>{arbitrage.category_name}</TextRow>}
                <WhiteDot />
                <TextRow>{arbitrage.league_name}</TextRow>
            </CategoryRow>
            <BetTypeRow>
                <TextRow>{arbitrage.home_team_name} - {arbitrage.away_team_name}</TextRow>
                <GrayDot/>
                <TextRow>{arbitrage.bet_type_name}</TextRow>
            </BetTypeRow>
            <Odds arbitrage={arbitrage} budget={budget} />
            <BottomTimestamp>
                <TimestampText>Aktualizováno v </TimestampText>
                {generatedAt}
            </BottomTimestamp>
        </Card>
    )
}
