import refreshIcon from "../../../icons/ffffff/autorenew.png"
import supportIcon from "../../../icons/ffffff/help_outline.png"
import signOutIcon from "../../../icons/ffffff/exit_to_app.png"
import React, {useCallback, useState} from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import menuIcon from "../../../icons/ffffff/menu.png";
import {BLACK, NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, WHITE} from "../../landingPage/colors";

const Container = styled.div`
    position: relative;
`

const InnerContainer = styled.div<{ expanded: number }>`
    @media screen and (max-width: 915px) {
        ${({ expanded }) => expanded === 1 ? "" : "display: none;"}
        
        position: absolute;
        right: 40px;
        background-color: ${THEMED_NEUTRAL};
        border: 1px solid ${NEUTRAL_600};
        border-radius: 10px;
        padding: 20px 30px 5px 20px;
        box-shadow: 0 0 10px ${BLACK};
    }
`

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`

const Link = styled.a`
    color: ${WHITE};
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: ${PRIMARY};
    }
`

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 5px;
`

const MenuIcon = styled.img`
    width: 32px;
    margin-right: 40px;
    
    &:hover {
        cursor: pointer;
    }

    @media only screen and (min-width: 916px) {
        display: none;
    }
`

type Props = {
    reload: () => void
}

export default function LinkContainer({ reload }: Props) {
    const [menuExpanded, setMenuExpanded] = useState(false)

    const signOut = useCallback(() => {
        Cookies.remove("session")
        window.location.href = "/"
    }, [])

    const toggleMenu = useCallback(() => {
        setMenuExpanded((menuExpanded) => !menuExpanded)
    }, [])

    return (
        <Container>
            <MenuIcon src={menuIcon} alt="Menu" onClick={toggleMenu} />
            <InnerContainer expanded={menuExpanded ? 1 : 0} >
                <LinkWrapper>
                    <Icon src={refreshIcon} alt="Refresh" />
                    <Link onClick={reload}>Aktualizovat</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Icon src={supportIcon} alt="Settings" />
                    <Link href="/settings">Nastavení</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Icon src={supportIcon} alt="Support" />
                    <Link href="/support">Podpora</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Icon src={signOutIcon} alt="Sign out" />
                    <Link onClick={signOut}>Odhlásit</Link>
                </LinkWrapper>
            </InnerContainer>
        </Container>
    )
}
