import React, {Dispatch, SetStateAction, useState} from "react";
import styled from "styled-components";
import {BLACK, NEUTRAL_300, NEUTRAL_500, WHITE} from "../../landingPage/colors";
import {fetchWrapper} from "../../../utils/fetchWrapper";
import config from "../../../config";

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${BLACK};
    opacity: 50%;
`

const Modal = styled.div`
    z-index: 1;
    background-color: ${WHITE};
    opacity: 100%;
    border: 1px solid ${NEUTRAL_500};
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
`

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px
`

const TeamName = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`

const Input = styled.input`
    padding: 5px;
`


const Label = styled.div`
    font-size: 14px;
    color: ${NEUTRAL_500};
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px
`

const Button = styled.div`
    border: 1px solid black;
    background-color: ${NEUTRAL_300};
    padding: 10px 20px;
    cursor: pointer;
    
    &:hover {
        background-color: ${NEUTRAL_500};
    }
`

export type ModalState = {
    showModal: boolean
    team: string
}


type Props = {
    modalState: ModalState
    setModalState: Dispatch<SetStateAction<ModalState>>
}

export function EventModal(props: Props) {
    const { modalState, setModalState } = props
    const { team } = modalState

    const [newTeam, setNewTeam] = useState(team)

    const submit = async () => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                sourceTeam: team,
                targetTeam: newTeam,
            }),
        }

        await fetchWrapper(`${config.apiUrl}/admin/sports/FOOTBALL/teams`, options)

        window.location.reload()
    }

    const closeModal = () => {
        setModalState((modalState) => ({
            ...modalState,
            showModal: false,
        }))
    }

    return (
        <Container>
            <Background onClick={closeModal}/>
            <Modal>
                <Title>Update team mapping</Title>
                <Section>
                    <TeamName>
                        <Label>Current team name</Label>
                        <div>{team}</div>
                    </TeamName>
                    <TeamName>
                        <Label>New team name</Label>
                        <Input value={newTeam} onChange={(event) => setNewTeam(event.target.value)} />
                    </TeamName>
                </Section>
                <ButtonWrapper>
                    <Button onClick={submit}>Submit</Button>
                    <Button onClick={closeModal}>Cancel</Button>
                </ButtonWrapper>
            </Modal>
        </Container>
    )
}