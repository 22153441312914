import styled, {keyframes} from "styled-components";
import {SectionContainer} from "../section-container";
import {SectionHeader} from "../section-header";

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const BookmakerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    @media only screen and (max-width: 1300px) {
        width: 600px;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 700px) {
        width: 400px;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 500px) {
        width: 300px;
        flex-wrap: wrap;
    }
`

const SecondaryBookmakerWrapper = styled(BookmakerWrapper)`
    @media only screen and (max-width: 1300px) {
        display: none;
    }
`

const toggle = keyframes`
    0% {
        transform: rotate(0);
    }

    20% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0);
    }
`

const Bookmaker = styled.img`
    width: 180px;
    border-radius: 16px;

    &:hover {
        animation: ${toggle} 200ms ease-in-out;
    }
`

const BOOKMAKERS = [
    {
        src: "synottip.png",
        alt: "Synottip",
    },
    {
        src: "sazka.jpg",
        alt: "sazkaBET",
    },
    {
        src: "forbet.png",
        alt: "forBET",
    },
    {
        src: "betano.png",
        alt: "Betano",
    },
    {
        src: "tipsport.png",
        alt: "Tipsport",
    },
    {
        src: "fortuna.jpg",
        alt: "Fortuna",
    },
]

export function Bookmakers() {
    return (
        <SectionContainer>
            <>
                <SectionHeader
                    title="Kompletní nabídka"
                    subtitle="Arbitrážní příležitosti hledáme u všech sázkových kanceláří s platnout českou licencí."
                />
                <InnerContainer>
                    <BookmakerWrapper>
                        {BOOKMAKERS.map(({src, alt}) => (
                            <Bookmaker key={src} src={`/assets/landing/bookmakers/${src}`} alt={alt}/>
                        ))}
                    </BookmakerWrapper>
                    <SecondaryBookmakerWrapper>
                        {BOOKMAKERS.reverse().map(({src, alt}) => (
                            <Bookmaker key={src} src={`/assets/landing/bookmakers/${src}`} alt={alt}/>
                        ))}
                    </SecondaryBookmakerWrapper>
                </InnerContainer>
            </>
        </SectionContainer>
    )
}