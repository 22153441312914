import styled from "styled-components";
import background from "./background.jpg"
import React, {RefObject} from "react";
import TopPanel from "./TopPanel";
import Content from "./Content";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
`

const Background = styled.div`
    position: absolute;
    width: 1200px;
    height: 100%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const InnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

type Props = {
    calculatorRef: RefObject<HTMLDivElement>
    howItWorksRef: RefObject<HTMLDivElement>
    pricingRef: RefObject<HTMLDivElement>
    screenshotsRef: RefObject<HTMLDivElement>
    faqRef: RefObject<HTMLDivElement>
}

export default function MainSection({ calculatorRef, howItWorksRef, pricingRef, screenshotsRef, faqRef }: Props) {
    return (
        <Container>
            <Background />
            <InnerContainer>
                <TopPanel
                    calculatorRef={calculatorRef}
                    howItWorksRef={howItWorksRef}
                    pricingRef={pricingRef}
                    screenshotsRef={screenshotsRef}
                    faqRef={faqRef}
                />
                <Content howItWorksRef={howItWorksRef} />
            </InnerContainer>
        </Container>
    )
}
