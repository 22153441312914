import styled from "styled-components";
import {Feature} from "./feature";
import {SectionHeader} from "../section-header";
import {SectionContainer} from "../section-container";
import {ForwardedRef, forwardRef} from "react";

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
    gap: 24px;
`

const FEATURES = [
    {
        title: "Analýza kurzové nabídky",
        text: "Nepřetržitě analyzujeme nabídku kurzů u všech sázkových kanceláří. Tyto kurzy náš software následně vzájemně kombinuje a hledá příležitosti k bezrizikovým sázkám.",
        icon: "search-icon",
    },
    {
        title: "Nalezení vhodného zápasu",
        text: "Hledáme takové zápasy, u kterých se kurzy sázkových kanceláří liší. Cílem je, aby bylo možné vsadit na obě varianty výsledku a zároveň dosáhnout zisku.",
        icon: "check-icon",
    },
    {
        title: "Kalkulace zisku",
        text: "Jakmile se taková kombinace kurzů objeví, spočítáme výši zaručeného zisku. Pokud je např. kurz u Fortuny na výhru 2.10 a kurz na prohru u Tipsportu 2.30, jistý zisk je 9,77 %.",
        icon: "profits-icon",
    },
    {
        title: "Zobrazení zápasu v aplikaci",
        text: "Ve chvíli, kdy nalezneme zápas umožňující dosáhnout zaručeného zisku, zobrazí se Vám přímo v naší webové aplikaci, do které máte neustále přístup.",
        icon: "mobile-icon",
    },
]

type Props = {
    howItWorksRef: ForwardedRef<HTMLDivElement>
}

function HowItWorksComponent(props: Props) {
    const {howItWorksRef} = props

    return (
        <SectionContainer ref={howItWorksRef}>
            <>
                <SectionHeader
                    title="Jak to funguje?"
                    subtitle="Arbitrážní sázení spočívá v jednoduchém principu kombinace dostatečně vysokých kurzů. Nalézt takové kombinace je však možné pouze s využitím softwaru - od toho je tu Safebets"
                />
                <FeatureContainer>
                    {FEATURES.map(({title, text, icon}) => (
                        <Feature key={title} title={title} text={text} icon={icon}/>
                    ))}
                </FeatureContainer>
            </>
        </SectionContainer>
    )
}

export const HowItWorks = forwardRef(HowItWorksComponent)