import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 600px;
    gap: 24px;
`

const Title = styled.span`
    font-family: "Kamerik", sans-serif;
    color: #FFF;
    font-size: 38px;
    font-weight: 700;
    text-align: center;
`

const Subtitle = styled.span`
    font-family: "BeVietnamPro", sans-serif;
    color: #DDD;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
`

type Props = {
    title: string
    subtitle: string
}

export function SectionHeader(props: Props) {
    const { title, subtitle } = props

    return (
        <Container>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Container>
    )
}