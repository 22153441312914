import styled from "styled-components";
import {formatMoney} from "../../../utils/formattors";
import {NEUTRAL_600, PRIMARY, WHITE} from "../colors";

const Label = styled.div`
    color: ${WHITE};
    font-size: 18px;
    margin: 10px 0 5px 0;
`

const SliderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`

const Slider = styled.input`
    -webkit-appearance: none;
    appearance: none;
    width: 80%;
    max-width: 400px;
    height: 10px;
    background: ${NEUTRAL_600};
    border-radius: 5px;
    outline: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: ${PRIMARY};
        border-radius: 50%;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: ${PRIMARY};
        border: none;
        border-radius: 50%;
        cursor: pointer;
    }
`

const SliderValue = styled.span`
    margin-left: 10px;
    font-size: 20px;
    width: 20%;
    color: ${PRIMARY};
    white-space: nowrap;
`

type Slider = {
    label: string
    min: number
    max: number
    value: number
    step: number
    unit: string
    setter: (value: number) => void
}

type Props = {
    slider: Slider
}

export default function SliderComponent({ slider }: Props) {
    return (
        <div>
            <Label>{slider.label}</Label>
            <SliderWrapper>
                <Slider
                    type="range"
                    min={slider.min}
                    max={slider.max}
                    value={slider.value}
                    step={slider.step}
                    onChange={(event) => slider.setter(parseFloat(event.target.value))}
                />
                <SliderValue>
                    {formatMoney(slider.value)}{slider.unit}
                </SliderValue>
            </SliderWrapper>
        </div>
    )
}
