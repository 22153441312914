export function formatMoney(value: number) {
    const digits = value.toString().split("").reverse()
    const groupedDigits: string[] = []

    digits.forEach((digit, index) => {
        if (index % 3 === 0 && index !== 0) {
            groupedDigits.push(" ")
        }

        groupedDigits.push(digit)
    })

    return groupedDigits.reverse().join("")

}
