import styled from "styled-components";
import {NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, WHITE} from "../landingPage/colors";
import {useCallback, useEffect, useState} from "react";
import {fetchWrapper} from "../../utils/fetchWrapper";
import config from "../../config";

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${THEMED_NEUTRAL};
    height: 100%;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    padding: 30px 40px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_600};
    border-radius: 10px;
`

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
`

const Button = styled.div`
    font-size: 14px;
    color: ${THEMED_NEUTRAL};
    border-radius: 10px;
    background-color: ${WHITE};
    padding: 5px 10px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`

const Label = styled.div`
    color: ${WHITE};
`

const Toggler = styled.div`
    color: ${PRIMARY};
    cursor: pointer;
    width: 50px;
    text-align: center;
`

export default function Settings() {
    const [checked, setChecked] = useState<boolean>()

    const apiUrl = `${config.apiUrl}/user/settings/`

    useEffect(() => {
        fetchWrapper(apiUrl)
            .then((response) => response.json())
            .then(({ emails_enabled: emailsEnabled }) => setChecked(emailsEnabled))
            .catch(console.error)
    }, [])

    const toggle = useCallback((newValue: boolean) => {
        setChecked(newValue)

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                emails_enabled: newValue,
            }),
        }

        fetchWrapper(apiUrl, options).catch(console.error)
    }, [])

    return (
        <Container>
            <InnerContainer>
                <Title>Nastavení</Title>
                    <Row>
                        <Label>Zasílat emailem nové arbitrážní příležitosti</Label>
                        {checked !== undefined && (
                            <Toggler onClick={() => toggle(!checked)}>
                                {checked ? "ANO" : "NE"}
                            </Toggler>
                        )}
                    </Row>
                <Button onClick={() => window.history.back()}>Jít zpět</Button>
            </InnerContainer>
        </Container>
    )
}
