import styled from "styled-components";
import {ChevronRightIcon} from "lucide-react"
import { useState} from "react";

type Props = {
    question: string
    answer: string
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid #CCC;
    padding: 32px;
    cursor: pointer;
    flex-grow: 0;
    color: #FFF;
    
    &:hover {
        border: 1px solid #51FF7A;
        color: #51FF7A;
    }
`

const QuestionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
`


const QuestionText = styled.div`
    font-family: "Kamerik", sans-serif;
    font-size: 24px;
    font-weight: 700;
`

const IconWrapper = styled.div<{ expanded?: true }>`
    transform: ${({ expanded }) => (expanded ? "rotate(90deg)" : "")};
    transition: all 0.1s ease-in-out;
`

const AnswerWrapper = styled.div<{ expanded?: true }>`
    overflow: hidden;
    max-height: ${({ expanded }) => (expanded ? "800px" : "0")};
    transition: max-height 0.5s ease-in-out;
`

const Answer = styled.div`
    padding-top: 24px;
    font-family: "BeVietnamPro", sans-serif;
    color: #DDD;
    font-size: 20px;
    line-height: 30px;
`

export function Question(props: Props) {
    const {question, answer} = props

    const [isExpanded, setIsExpanded] = useState(false)

    const toggle = () => setIsExpanded((isExpanded) => !isExpanded)


    return (
        <Container onClick={toggle}>
            <QuestionWrapper>
                <QuestionText>{question}</QuestionText>
                <IconWrapper expanded={isExpanded || undefined}>
                    <ChevronRightIcon color="#51FF7A" strokeWidth="3" size="32"/>
                </IconWrapper>
            </QuestionWrapper>
            <AnswerWrapper expanded={isExpanded || undefined}>
                <Answer>{answer}</Answer>
            </AnswerWrapper>
        </Container>
    )
}