import config from "../config";
import * as Sentry from "@sentry/react";
import {fetchWrapper} from "./fetchWrapper";

export default function redirectToApp() {
    fetchWrapper(`${config.apiUrl}/user/`)
        .then((response) => response.json())
        .then((data) => {
            window.location.href = data.plan_id ? "/app" : "/plan"
        })
        .catch((error) => {
            Sentry.captureException(error)
            window.location.href = "/app"
        })
}
