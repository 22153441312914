import styled from "styled-components";
import {Review} from "./review";
import {SectionContainer} from "../section-container";
import {SectionHeader} from "../section-header";
import {ArrowLeftCircleIcon, ArrowRightCircleIcon} from "lucide-react"
import {useEffect, useMemo, useRef, useState} from "react";

const NavigationButton = styled.div<{ enabled: number }>`
    color: ${({enabled}) => enabled ? "#DDDDDD" : "#333333"};
    cursor: ${({enabled}) => enabled ? "pointer" : "auto"};

    &:hover {
        color: ${({enabled}) => enabled ? "rgb(81, 255, 122)" : "#333333"};
    }
`

const WideNavigationButton = styled(NavigationButton)`
    @media only screen and (max-width: 600px) {
        display: none;
    }
`

const NarrowNavigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media only screen and (min-width: 601px) {
        display: none;
    }
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`

const CARD_GAP = 24

const ReviewContainer = styled.div<{ large: number, medium: number, small: number }>`
    overflow-x: hidden;
    width: ${({large}) => large}px;

    @media only screen and (max-width: 1400px) {
        width: ${({medium}) => medium}px;
    }

    @media only screen and (max-width: 1000px) {
        width: ${({small}) => small}px;
    }
`

const ReviewInnerContainer = styled.div<{ shift: number }>`
    display: flex;
    flex-direction: row;
    align-items: start;
    width: min-content;
    gap: 24px;
    transition: all 500ms ease-in-out;
    transform: ${({shift}) => `translateX(${shift}px)`};
`

const REVIEWS = [
    {
        name: "Marek Kopecký",
        text: "Zpočátku jsem byl vůči tomu skeptický, nicméně po vyzkoušení 3 dnů zdarma mě výsledky přesvědčili. Od té doby nesázím podle nikoho jiného.",
        stars: 5,
        joinedAt: "3/2024",
    },
    {
        name: "Jakub Ritter",
        text: "Nejlepší předplatné, jaké jsem si kdy pořídil. Za první 3 týdny už se mi zaplatilo mnohonásobně. Rozhodně mám v plánu dál využívat vašich služeb!",
        stars: 5,
        joinedAt: "5/2024",
    },
    {
        name: "Patrik Soukup",
        text: "Aplikace funguje skvěle, každý den se objeví aspoň jedna větší arbitráž. Chybí mi pouze šitší nabídka sportů, ale jinak je to úplná pecka.",
        stars: 4,
        joinedAt: "11/2024",
    },
    {
        name: "Jan Semelka",
        text: "Za první dva měsíce jsem vydělal přes 23 tisíc. Naprostá spokojenost!! Jediné co mi vadí, je nutnost více účtů u různých sázkovek, ale za ty peníze :) ",
        stars: 4,
        joinedAt: "2/2024",
    },
    {
        name: "Markéta Zavadilová",
        text: "Přítel netuší, že přes Vás sázím a dnes už za mnou přišel, abych mu poradila s tiketem. Je skvělý, že někdo v Česku spustil takovouhle službu.",
        stars: 5,
        joinedAt: "4/2024",
    },
    {
        name: "Zbyněk Pospíšil",
        text: "Na různých sázkových poradenstvích jsem dřív prodělal několik tisíc. Jsem rád, že jsem konečně našel někoho, u koho můžu mít jistotu výdělku.",
        stars: 5,
        joinedAt: "5/2024",
    },
    {
        name: "Sofie Junová",
        text: "Po celou dobu skvělá komunikace. Se vším poradili, pomohli, vysvětlili.. Zatím zkouším druhý měsíc a stále zcela bez chyby.",
        stars: 5,
        joinedAt: "7/2024",
    },
    {
        name: "Lukáš Pek",
        text: "O arbitrážním sázení jsem slyšel dříve v Anglii a pořád jsem si říkal, kdy se u nás najde někdo, kdo to spustí taky. Už bylo načase, díky moc!",
        stars: 5,
        joinedAt: "12/2023",
    },
]

export function Reviews() {
    const [reviewIndex, setIndexReview] = useState(-1)
    const reviewContainerRef = useRef<HTMLDivElement>(null)
    const reviewRef = useRef<HTMLDivElement>(null)

    const containerWidth = reviewContainerRef.current?.getBoundingClientRect().width
    const cardWidth = reviewRef.current?.getBoundingClientRect().width || 0

    const reviewsLargeWidth = 3 * cardWidth + 2 * CARD_GAP
    const reviewsMediumWidth = 2 * cardWidth + CARD_GAP
    const reviewsSmallWidth = cardWidth

    const isPreviousAvailable = reviewIndex > 0

    const cardCount = useMemo(() => {
        if (containerWidth === reviewsLargeWidth) {
            return 3
        }

        if (containerWidth === reviewsMediumWidth) {
            return 2
        }

        if (containerWidth === reviewsSmallWidth) {
            return 1
        }

        return 0
    }, [reviewContainerRef.current, reviewRef.current, containerWidth, cardWidth])

    useEffect(() => {
        setIndexReview(0)
    }, []);

    const isNextAvailable = reviewIndex < REVIEWS.length - cardCount

    const onPreviousClick = () => {
        if (isPreviousAvailable) {
            setIndexReview((index) => index - 1)
        }
    }

    const onNextClick = () => {
        if (isNextAvailable) {
            setIndexReview((index) => index + 1)
        }
    }

    return (
        <SectionContainer>
            <>
                <SectionHeader title="Jak hodnotí Safebets?" subtitle="Safebets již vyzkoušely stovky lidí - podívejte se, jak naši službu hodnotí s odstupem
                    času."/>
                <InnerContainer>
                    <WideNavigationButton enabled={isPreviousAvailable ? 1: 0} onClick={onPreviousClick}>
                        <ArrowLeftCircleIcon size={64} strokeWidth={1}/>
                    </WideNavigationButton>
                    <ReviewContainer
                        ref={reviewContainerRef}
                        large={reviewsLargeWidth}
                        medium={reviewsMediumWidth}
                        small={reviewsSmallWidth}
                    >
                        <ReviewInnerContainer shift={-(cardWidth + CARD_GAP) * reviewIndex}>
                            {REVIEWS.map(({name, text, stars, joinedAt}, index) => (
                                <Review ref={index === 0 ? reviewRef : undefined} key={name} name={name} text={text}
                                        stars={stars} joinedAt={joinedAt}/>
                            ))}
                        </ReviewInnerContainer>
                    </ReviewContainer>
                    <WideNavigationButton enabled={isNextAvailable ? 1 : 0} onClick={onNextClick}>
                        <ArrowRightCircleIcon size={64} strokeWidth={1}/>
                    </WideNavigationButton>
                    <NarrowNavigation>
                        <NavigationButton enabled={isPreviousAvailable ? 1 : 0} onClick={onPreviousClick}>
                            <ArrowLeftCircleIcon size={64} strokeWidth={1}/>
                        </NavigationButton>
                        <NavigationButton enabled={isNextAvailable ? 1 : 0} onClick={onNextClick}>
                            <ArrowRightCircleIcon size={64} strokeWidth={1}/>
                        </NavigationButton>
                    </NarrowNavigation>
                </InnerContainer>
            </>
        </SectionContainer>
    )
}