import React, {useCallback} from "react";
import styled from "styled-components";
import {BLACK, NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, TRANSPARENT_THEMED_NEUTRAL, WHITE} from "../../landingPage/colors";
import LinkContainer from "./LinkContainer";

const Container = styled.div`
    position: fixed;
    width: 160px;
    background-color: ${TRANSPARENT_THEMED_NEUTRAL};
    border: 1px solid ${NEUTRAL_600};
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    color: ${WHITE};
    font-family: sans-serif;

    @media only screen and (max-width: 915px) {
        width: 100%;
        top: 0;
        left: 0;
        flex-direction: row;
        align-items: center;
        background-color: ${THEMED_NEUTRAL};
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid ${NEUTRAL_600};
        z-index: 10;
        box-shadow: 0 0 10px ${BLACK};
        justify-content: space-between;
    }
`

const BetContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 915px) {
        flex-direction: row;
        align-items: center;
    }
`

const BetTitle = styled.span`
    font-size: 16px;
    color: ${PRIMARY};
    margin: 0 0 10px 0;

    @media screen and (max-width: 915px) {
        margin: 0 10px 0 0;
    }
`

const BetInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 25px;
    margin-bottom: 60px;
    border: 1px solid ${PRIMARY};
    align-items: center;
    border-radius: 10px;
    justify-content: end;
    padding: 0 10px;

    @media screen and (max-width: 915px) {
        margin-bottom: 0;
    }
`

const BetInput = styled.input`
    width: 80px;
    background: none;
    border: 0;
    color: ${PRIMARY};
    flex: 1;
    
    &:focus {
        outline: none;
    }
    
    &::placeholder {
        color: ${PRIMARY};
    }
`

const BetCurrency = styled.div`
    position: relative;
    font-size: 16px;
    color: ${PRIMARY};
    width: 20px;
    vertical-align: middle;
`

type Props = {
    budget: number
    setBudget: (budget: number) => void
    reload: () => void
}

export default function Panel({ budget, setBudget, reload }: Props) {
    const onBetChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBudget(parseFloat(event.target.value || "0"))
    }, [setBudget])

    return (
        <Container>
            <BetContainer>
                <BetTitle>Vsadím</BetTitle>
                <BetInputWrapper>
                    <BetInput value={budget} onChange={onBetChange} />
                    <BetCurrency>Kč</BetCurrency>
                </BetInputWrapper>
            </BetContainer>
            <LinkContainer reload={reload} />
        </Container>
    )
}
