import {useCallback} from "react";
import {fetchWrapper} from "../../utils/fetchWrapper";
import config from "../../config";
import styled from "styled-components";
import {NEUTRAL_500, NEUTRAL_600, PRIMARY, THEMED_NEUTRAL, WHITE} from "../landingPage/colors";


const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${THEMED_NEUTRAL};
    height: 100%;
    width: 100%;
`

const InnerContainer = styled.div`
    display: flex;
    max-width: 300px;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    padding: 30px 40px;
    color: ${WHITE};
    font-family: sans-serif;
    border: 1px solid ${NEUTRAL_600};
    border-radius: 10px;
`

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 25px;
    color: ${WHITE};
`

const Label = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
    color: ${WHITE};
    text-align: center;
    line-height: 1.5;
`

const Button = styled.a`
    border: 1px solid ${PRIMARY};
    color: ${PRIMARY};
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    margin: 10px 0 40px 0;

    &:hover {
        cursor: pointer;
        background-color: ${PRIMARY};
        color: ${THEMED_NEUTRAL};
    }
`

const Text = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: ${NEUTRAL_500};
    text-align: center;
    line-height: 1.5;
`

const Link = styled.a`
    text-decoration: none;
    color: ${WHITE};
    
    &:hover {
        color: ${PRIMARY};
    }
`

export default function Plan() {
    const redirectToStripe = useCallback(async () => {
        const url = `${config.apiUrl}/plan/`
        const response = await fetchWrapper(url)
        const data = await response.json()

        if (response.ok) {
            window.location.href = data.checkout_url
        }
    }, [])

    return (
        <Container>
            <InnerContainer>
                <Title>Platba</Title>
                <Label>Safebets je placená aplikace s měsíčním předplatným.</Label>
                <Button onClick={redirectToStripe}>Přejít k platbě</Button>
                <Text>
                    <span>V případě dotazu nás kontaktujte na </span>
                    <Link href="mailto:info@safebets.cz">info@safebets.cz</Link>
                    <span>.</span>
                </Text>
            </InnerContainer>
        </Container>
    )
}
