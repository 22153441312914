import styled from "styled-components";
import {NEUTRAL_300} from "../../landingPage/colors";
import React, {Dispatch, SetStateAction} from "react";
import {ModalState} from "./EventModal";

const Container = styled.td`
    width: 400px;
    padding: 5px;
    border: 1px solid ${NEUTRAL_300};
    cursor: pointer;
`

type Props = {
    setModalState: Dispatch<SetStateAction<ModalState>>
    team: string
}

export function TeamCell(props: Props) {
    const {setModalState, team} = props

    return (
        <Container
            onClick={() => {
                setModalState({
                    showModal: true,
                    team,
                })
            }}
        >
            {team}
        </Container>
    )
}