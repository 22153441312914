import styled from "styled-components";
import {CheckCircle2Icon} from "lucide-react"
import {SectionContainer} from "../section-container";
import {SectionHeader} from "../section-header";

const Card = styled.div`
    font-family: "BeVietnamPro", sans-serif;
    display: flex;
    flex-direction: column;
    background-color: rgb(81, 255, 122);
    gap: 48px;
    padding: 48px;
    border-radius: 24px;
`

const FeatureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Feature = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: start;
`

const FeatureText = styled.div`
    display: flex;
`

const CheckmarkWrapper = styled.div`
    flex-shrink: 0;
`

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
`

const Price = styled.div`
    font-size: 32px;
    font-weight: 700;
    white-space: nowrap;
`

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const FeatureTitle = styled.div`
    font-weight: 700;
`

const Button = styled.a`
    background-color: #1E1E1E;
    color: #FFFFFF;
    padding: 16px;
    text-align: center;
    font-size: 18px;
    border-radius: 99999px;
    transition: all 200ms ease-in-out;
    text-decoration: none;

    &:hover {
        transform: scale(1.1);
    }
`

const InnerContainer = styled.div`
    padding: 0 10px;
`

const FEATURES = [
    "Kompletní přístup do aplikace",
    "Automatická kalkulace vkladu i zisku",
    "Notifikace o arbitrážních příležitostech",
    "Aktualizace kurzů každých několik vteřin",
    "Customer support",
]

export function Pricing() {
    return (
        <SectionContainer>
            <>
                <SectionHeader
                    title="Cena"
                    subtitle="Po vypršení zkušební lhůty zdarma se členství platí každý měsíc. Platby jsou zabezpečeny pomocí služby Stripe."
                />
                <InnerContainer>
                    <Card>
                        <PriceWrapper>
                            <Price>890 Kč</Price>
                            <div>měsíčně</div>
                        </PriceWrapper>
                        <FeatureContainer>
                            <FeatureTitle>Členstvím získáte:</FeatureTitle>
                            <FeatureWrapper>
                                {FEATURES.map((feature) => (
                                    <Feature key={feature}>
                                        <CheckmarkWrapper>
                                            <CheckCircle2Icon fill="#1E1E1E" stroke="#51FF7A"/>
                                        </CheckmarkWrapper>
                                        <FeatureText>{feature}</FeatureText>
                                    </Feature>
                                ))}
                            </FeatureWrapper>
                        </FeatureContainer>
                        <Button href="/auth/sign-up/">Vyzkoušet zdarma</Button>
                    </Card>
                </InnerContainer>
            </>
        </SectionContainer>
    )
}