import styled from "styled-components";
import {forwardRef, Ref} from "react";

type Props = {
    name: string
    text: string
    stars: number
    joinedAt: string
}

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 300px;
    padding: 40px;
    border: 1px solid #DDD;
    border-radius: 20px;
    font-family: "BeVietnamPro", sans-serif;

    @media only screen and (max-width: 600px) {
        width: 200px;
        height: 400px;
    }
`

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const Text = styled.span`
    color: #CCC;
    font-size: 18px;
    line-height: 28px;
`

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Name = styled.span`
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
`

const JoinedAt = styled.span`
    color: rgb(81, 255, 122);
    font-size: 20px;
    font-weight: 700;
`

const Stars = styled.img`
    height: 20px;
    width: 100px;
`

function ReviewComponent(props: Props, ref?: Ref<HTMLDivElement>) {
    const {name, text, stars, joinedAt} = props

    return (
        <Card ref={ref}>
            <MainWrapper>
                <Stars src={`/assets/landing/ratings/${stars}-stars.png`} alt="Stars"/>
                <Text>{text}</Text>
            </MainWrapper>
            <Footer>
                <Name>{name}</Name>
                <JoinedAt>{joinedAt}</JoinedAt>
            </Footer>
        </Card>
    )
}

export const Review = forwardRef(ReviewComponent)
