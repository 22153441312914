import React, {ForwardedRef, forwardRef} from "react";
import styled from "styled-components";
import {WHITE, NEUTRAL_300, NEUTRAL_500, PRIMARY, BLACK, THEMED_NEUTRAL} from "../colors";
import checkIcon from "../../../icons/51ff7a/check.png";

const Container = styled.div`
    color: ${WHITE};
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Sections = styled.div`
    display: flex;
    flex-direction: row;
    gap: 100px;
    padding: 0 50px;
    
    @media only screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        gap: 50px;
    }
`

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const InnerSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
`

const Title = styled.div`
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 50px;
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEMED_NEUTRAL};
    width: 300px;
    border-radius: 20px;
    padding: 40px 20px;
`

const CardTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
`

const MonthlyText = styled.div`
    font-size: 18px;
    color: ${NEUTRAL_500};
`

const TrialText = styled.div`
    font-size: 18px;
    margin-top: 70px;
    
`

const OldPrice = styled.div`
    color: ${NEUTRAL_300};
    font-size: 24px;
    margin-top: 10px;
    text-decoration: line-through;
`

const Price = styled.div`
    font-weight: 700;
    font-size: 32px;
    margin: 10px 0 20px 0;
    color: ${PRIMARY};
    text-shadow: 0 0 5px ${PRIMARY};
`

const Feature = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`

const Image = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`

const Text = styled.div`
    color: ${NEUTRAL_500};
    font-size: 14px;
    margin: 30px 0 50px 40px;
`

const PrimaryButton = styled.a`
    position: relative;
    background-color: ${PRIMARY};
    color: ${BLACK};
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 50px;
    white-space: nowrap;
    text-decoration: none;
    margin-top: 20px;
    
    &:hover {
        box-shadow: 0 0 10px ${PRIMARY}
    }
`

type Props = {}

const features = [
    "Kompletní přístup",
    "Možnost vyzkoušet na 3 dny zdarma",
    "Desítky arbitrážních příležitostí denně",
    "Automatický výpočet zhodnocení i vkladu",
    "Nejaktuálnější kurzy - aktualizace každých 15 vteřin",
    "Přímý proklik na všechny sázkové kanceláře",
]

function Pricing(_props: Props, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <Container ref={ref}>
            <Title>Cena předplatného</Title>
            <Sections>
                <Section>
                    <InnerSection>
                        {features.map(feature => (
                            <Feature>
                                <Image src={checkIcon} alt="Odrážka" />
                                {feature}
                            </Feature>
                        ))}
                        <Text>Platby jsou zabezpečeny pomocí služby Stripe.</Text>
                    </InnerSection>
                </Section>
                <Section>
                    <Card>
                        <CardTitle>Měsíční členství</CardTitle>
                        <OldPrice>1190 Kč</OldPrice>
                        <Price>890 Kč</Price>
                        <MonthlyText>Měsíčně</MonthlyText>
                        <TrialText>3 denní free trial</TrialText>
                        <PrimaryButton href="/auth/sign-up/">Vyzkoušet zdarma</PrimaryButton>
                    </Card>
                </Section>
            </Sections>
        </Container>
    )
}

export default forwardRef(Pricing)
