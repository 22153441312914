import React from 'react';
import Arbitrages from "./components/arbitrages";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LandingPage from "./components/landingPage";
import Support from "./components/support";
import Settings from "./components/settings";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Plan from "./components/plan";
import PasswordReset from "./components/PasswordReset"
import {AdminHome, AdminEvents} from './components/admin'
import * as Sentry from "@sentry/react";
import {ENV} from "./config";
import {NewLandingPage} from "./components/new-landing-page";

// @ts-ignore
if (ENV === "production" || true) {
    Sentry.init({
        dsn: "https://27dd9ea4d94fc8997f65c9e1dc24a481@o4506548766310400.ingest.sentry.io/4506548767752192",
        integrations: [
            new Sentry.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <NewLandingPage/>,
    },
    {
        path: "app",
        element: <Arbitrages/>,
    },
    {
        path: "support",
        element: <Support/>,
    },
    {
        path: "settings",
        element: <Settings/>,
    },
    {
        path: "/auth/sign-in",
        element: <SignIn/>,
    },
    {
        path: "/auth/sign-up",
        element: <SignUp/>,
    },
    {
        path: "/plan",
        element: <Plan/>,
    },
    {
        path: "/auth/password-reset",
        element: <PasswordReset/>
    },
    {
        path: "/admin",
        element: <AdminHome/>
    },
    {
        path: "/admin/events",
        element: <AdminEvents/>
    },
    {
        path: "/landing",
        element: <LandingPage/>
    }
]);

export default function App() {
    return <RouterProvider router={router}/>
}
